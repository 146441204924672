import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, isRef as _isRef } from "vue";
const _hoisted_1 = {
  class: "min-h-full"
};
const _hoisted_2 = {
  key: 0,
  class: "relative p-4 sm:p-7 min-w-0"
};
import Header from '@/components/header/index.vue';
import LeftNavList from '@/components/header/leftNavList.vue';
import DialogSwitchChain from '@/views/components/common/dialog-switchChain.vue';
import Maintenance from '@/components/maintenance/maintenance.vue';
import eventEmitter from '@/utils/eventEmitter';
import ConnectPanel from '@/components/connectPanel.vue';
import { onMounted, getCurrentInstance, ref, computed, watchEffect, provide, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { checkConnectStatus, initWs } from '@/utils/web3Hooks';
import { useI18n } from 'vue-i18n';
import { getI18nLocal } from '@/i18n/i18n';
import useSetup from '@/hooks/useSetup';
import { loadToken, parseToken } from './utils/common';
import { useRoute, useRouter } from 'vue-router';
export default {
  __name: 'App',
  setup(__props) {
    const {
      locale
    } = useI18n({
      useScope: 'global'
    });
    const {
      checkSetup
    } = useSetup();
    const routeReload = ref(0);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const isConnected = computed(() => store.state._info.connectInfo.connected);
    const chainId = computed(() => store.state._info.connectInfo.chainId);
    const merchantId = computed(() => store.state._info.merchantInfo.merchantId);
    const routerIsReady = ref(false);
    router.isReady().then(() => {
      routerIsReady.value = true;
    });
    const switchChainVisible = computed(() => !store.state._info.connectInfo.supported);
    const globalProperties = getCurrentInstance().appContext.config.globalProperties;
    const elLocale = computed(() => {
      return getI18nLocal(locale.value);
    });

    // 维护状态
    const maintenance = computed(() => store.state._config.maintenance);

    // 左侧导航抽屉显示
    const navDrawerVisible = ref(false);

    // 初始化当前网络配置
    const initNetworkInfo = () => {
      // 兼容当前版本store
      const {
        allNetwork
      } = store.state._config;
      const contractConfig = allNetwork.find(item => item.chainId === chainId.value);
      if (contractConfig) {
        store.commit('_config/Update_contractConfig', contractConfig);
      }
    };

    // 初始化成功
    const loginInit = () => {
      if ('QrCodePaymentStep' === route.name && routeReload.value > 0) return;
      console.log('loginInit', routeReload.value);
      routeReload.value += 1; // 每次登录成功刷新路由
      // 获取币种列表
      store.dispatch('_config/querySymbol', {
        bizType: '',
        chainId: chainId.value
      });
    };

    // 登录成功
    const loginSuccess = () => {
      console.log('loginSuccess', routeReload.value);
      store.commit('_info/Update_loginInfo', parseToken());
      routeReload.value += 1; // 每次登录成功刷新路由
      initNetworkInfo();
      // 检查初始化进度
      checkSetup();
      // 获取币种列表
      store.dispatch('_config/querySymbol', {
        bizType: '',
        chainId: chainId.value
      });
      // 合约列表
      store.dispatch('_info/queryContractList');
      // 获取余额列表
      store.dispatch('_info/queryBalance');
      // 初始化ws
      initWs();
    };
    router.beforeEach((to, from) => {
      const token = loadToken();
      const contractFlag = store.state._info.merchantInfo.contractFlag;

      // contractFlag没有权限访问合约模块的账户重定向到二维码模块
      if (to.path.startsWith('/contract-payment') && !contractFlag && token) {
        return {
          name: 'QrCodePayment'
        };
      }
      // 返回 false 以取消导航
      return true;
    });
    watchEffect(() => {
      if (isConnected.value && chainId.value) {
        // 兼容当前版本store
        initNetworkInfo();
      }
    });
    onMounted(() => {
      // 调试用
      window['vm'] = globalProperties;
      window['store'] = store;
      const token = loadToken();

      // 合约地址配置
      const getSignatureStr = () => {
        return store.dispatch('_config/signatureStr');
      };
      const getAllNetwork = () => {
        return store.dispatch('_config/queryAllNetwork');
      };
      // 初始化自动化连接
      Promise.all([getSignatureStr(), getAllNetwork()]).then(() => {
        checkConnectStatus();
        token && loginSuccess();
      });
      eventEmitter.on('LOGIN_INIT', loginInit);
      eventEmitter.on('LOGIN_SUCCESS', loginSuccess);
    });
    onUnmounted(() => {
      eventEmitter.off('LOGIN_INIT', loginInit);
      eventEmitter.off('LOGIN_SUCCESS', loginSuccess);
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_el_drawer = _resolveComponent("el-drawer");
      const _component_el_config_provider = _resolveComponent("el-config-provider");
      return _openBlock(), _createBlock(_component_el_config_provider, {
        message: {
          max: 3
        },
        locale: _unref(elLocale)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(Header, {
          onToggleMenu: _cache[0] || (_cache[0] = $event => navDrawerVisible.value = !navDrawerVisible.value)
        }), routerIsReady.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_unref(maintenance) === 1 ? (_openBlock(), _createBlock(Maintenance, {
          key: 0
        })) : _createCommentVNode("", true), !_unref(isConnected) && (_ctx.$route.path.includes('/contract-payment/') || '/' === _ctx.$route.path) ? (_openBlock(), _createBlock(ConnectPanel, {
          key: 1
        })) : (_openBlock(), _createBlock(_component_router_view, {
          key: routeReload.value
        }, {
          default: _withCtx(({
            Component
          }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: 0
          })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: 0
          })) : _createCommentVNode("", true)]),
          _: 1
        }))])) : _createCommentVNode("", true)]), _createVNode(_component_el_drawer, {
          modelValue: navDrawerVisible.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => navDrawerVisible.value = $event),
          class: "!w-72",
          direction: "ltr",
          "with-header": false
        }, {
          default: _withCtx(() => [_createVNode(LeftNavList, {
            onClick: _cache[1] || (_cache[1] = $event => navDrawerVisible.value = false)
          })]),
          _: 1
        }, 8, ["modelValue"]), _createVNode(DialogSwitchChain, {
          modelValue: _unref(switchChainVisible),
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _isRef(switchChainVisible) ? switchChainVisible.value = $event : null)
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["locale"]);
    };
  }
};