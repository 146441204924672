import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2de73e4b"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "connectPanel"
};
const _hoisted_2 = {
  class: "label"
};
const _hoisted_3 = {
  class: "introduction"
};
const _hoisted_4 = {
  class: "btnBox"
};
const _hoisted_5 = {
  key: 0,
  class: "text-center mt-4"
};
import { computed, ref } from 'vue';
import ConnectDialog from './connectDialog.vue';
import { connectWallet } from '@/utils/web3Hooks';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
export default {
  __name: 'connectPanel',
  setup(__props) {
    const store = useStore();
    const route = useRoute();
    const connectDialogVisible = ref(false);

    // 是否显示创建账户的按钮
    const showCreateBtn = computed(() => {
      return !store.state._info.loginInfo.address && route.name === 'Login';
    });
    const connectHandle = () => {
      // 连接钱包
      connectDialogVisible.value = true;
    };
    const handlerConnect = async option => {
      GAEvent.trackEvent(`connectWallet,login`, {
        walletType: option.wallet
      });
      connectWallet(option).then(() => {
        connectDialogVisible.value = false;
      }).catch(e => console.log(e));
    };
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('common.connectTitle')), 1), _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('common.connectTips')), 1), _createElementVNode("div", _hoisted_4, [_createElementVNode("button", {
        class: "btn large w-full",
        "data-ga": "connectWallet,loginDialog",
        onClick: connectHandle
      }, _toDisplayString(_ctx.$t('common.connect')), 1), _unref(showCreateBtn) ? (_openBlock(), _createElementBlock("p", _hoisted_5, [_createTextVNode(_toDisplayString(_ctx.$t('login.dontHaveAccountYet')) + " ", 1), _createVNode(_component_router_link, {
        to: "/qrcode-payment/step/0",
        class: "text-link"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('login.createAccount')), 1)]),
        _: 1
      })])) : _createCommentVNode("", true)]), _createVNode(ConnectDialog, {
        modelValue: connectDialogVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => connectDialogVisible.value = $event),
        onConnect: handlerConnect
      }, null, 8, ["modelValue"])]);
    };
  }
};